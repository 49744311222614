body div#page-Note div.form-section:nth-child(5),
body div#page-Note div.form-section:nth-child(6) {
  display: None;
}
body.role_pcg_web_manager div#page-Note div.form-section:nth-child(5),
body.role_pcg_web_manager div#page-Note div.form-section:nth-child(6) {
  display: block;
}
body div#page-Note div.custom-actions:nth-child(2) > button {
  display: None;
}
body.role_pcg_web_manager div#page-Note div.custom-actions:nth-child(2) > button {
  display: block;
}
/* sources section, add a hint that these fields belong together 
   use this to identify the right section..
.row.form-section.visible-section:nth-child(9) {
    background-color: aliceblue;
}
 */
.row.form-section.visible-section.contains-sources_section .contains-profile_type form,
.row.form-section.visible-section.contains-sources_section .contains-origin form {
  border: 1px solid #d1d8dd;
  /* color of other frappe borders */
  border-radius: 4px;
  padding-right: 10px;
  padding-left: 10px;
}
.form-chart-widgets .widget-group {
  margin-bottom: 0;
}
.standard-sidebar-item .icon-fa {
  margin-left: var(--margin-sm);
  padding-right: 2px;
}
/* let sections appear to belong together */
.pcg_section_break_ass_variant_1,
.pcg_section_break_ass_variant_2,
.pcg_section_break_ass_variant_3,
.pcg_section_break_ass_variant_4 {
  border-top: none !important;
  padding-top: 0;
}
/*
  collapse these sections together
  */
.form-section.card-section.pcg_settings_for_item_prices_section {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.form-section.card-section.pcg_item_prices_by_price_lists_section {
  border-left: none;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  margin-top: calc(var(--margin-lg) * -1);
}
.form-section.card-section.pcg_item_prices_by_price_lists_section .section-head {
  display: none;
}
.pcg_shop_not_enabled a[href='/app/shop'] {
  display: none !important;
}
.layout-side-section {
  padding-left: 5px !important;
  padding-right: 0 !important;
}
body.pcg_shop_not_enabled .shop-icon {
  display: none;
}
.import-update span,
.export-update span {
  padding: 3px;
  border-radius: 4px;
  line-height: 24px;
  /* color: #36414c; */
  color: #36414c82;
  padding-right: 4px;
}
.import-update.pcg-warn span,
.export-update.pcg-warn span {
  background-color: #ffa00a;
  /* #ffa00a61 */
}
.import-update.pcg-error span,
.export-update.pcg-error span {
  background-color: #ff5858;
}
.import-update.pcg-ok span,
.export-update.pcg-ok span {
  background-color: #e6f5d8;
  color: #36414c82;
}
.pcg-warn {
  color: #ff5858;
}
.pcg-red {
  color: #ff5858;
}
.pcg-orange {
  color: #ffa00a;
}
.pcg-green {
  color: green;
}
.datepicker.active {
  z-index: 1000000005 !important;
}
.modal.fade.show.driver-fix-stacking {
  z-index: 100005 !important;
}
.modal .to_section form {
  flex-wrap: wrap;
}
.modal .to_section form [data-fieldname="reply_to"] {
  min-width: 90px;
  margin-right: 10px;
}
.section-body:not(.collapsed) .frappe-control.fixed[data-fieldname="tour_map"] {
  position: fixed;
  width: 500px;
  z-index: 500;
  top: 150px;
}
/*
[data-fieldname="tour_position_table"] .form-grid{
    max-height: 400px;
    overflow-y: scroll;
}
*/
.loljo {
  /* background-color: blueviolet; */
}
li.tree-node.node-to-deep::after {
  position: absolute;
  padding-right: auto;
  padding-left: 15px;
  text-align: end;
  color: #e6f5d8;
  top: 0;
  left: 0;
  /* left: 0; */
  /* width: 100%;
    height: 100%; */
  background-color: red;
  opacity: 0.4;
}
li.tree-node.node-to-deep.en::after {
  content: "Subnodes not yet supported";
}
li.tree-node.node-to-deep.de::after {
  content: "Unternavigationen sind noch nicht unterstützt";
}
hr.purchase-preview-hr {
  margin-bottom: 20px;
}
#page-Workspaces .widget {
  box-shadow: var(--card-shadow);
  min-height: 45px;
}
.error-value {
  background-color: rgba(243, 39, 39, 0.08);
}
/* hide bewertungen */
.form-reviews {
  display: none;
}
/* fix */
.widget.dashboard-widget-box.heatmap-chart {
  overflow: auto;
}
.form-dashboard .widget-group {
  width: 100%;
}
.bg-grey-important {
  background-color: #e8eaeb !important;
}
.reorder-dialog {
  width: 900px;
  max-width: 60%;
}
.frappe-control[data-fieldname="reply_to"] {
  padding-right: 10px;
  padding-left: 10px;
}
.warning-invoice-positions {
  padding: 2px;
  padding: 3px;
  padding-right: 5px;
  color: var(--text-on-blue);
  background: var(--bg-blue);
}
.leaflet-control.hide-lines {
  background-color: white;
  padding: 5px;
}
.leaflet-control.hide-lines:hover {
  cursor: pointer;
}
.tour-optimize-button {
  display: flex;
  flex-direction: row-reverse;
  margin-bottom: 5px;
  justify-content: space-between;
  margin-bottom: 15px;
}
.leaflet-marker-icon.start-marker {
  filter: hue-rotate(240deg);
}
.leaflet-marker-icon.end-marker {
  filter: hue-rotate(150deg);
}
.control-value.like-disabled-input.for-description {
  white-space-collapse: collapse !important;
}
td.subtable,
th.subtable {
  padding-right: 10px;
  padding-left: 10px;
  padding-top: 3px;
  padding-bottom: 3px;
}
td.subcheck {
  padding-top: 5px;
}
.results {
  border: 1px solid #d1d8dd;
  border-radius: 6px;
  height: 300px;
  overflow: auto;
}
.dt-row:nth-child(2n) .dt-cell {
  background: #c0c0c0100%f;
}
